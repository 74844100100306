<!--预警发送日志-->
<template>
  <section class="send-list">
    <section class="filter">
      <el-form :inline="true" :model="form">
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="form.startDate"
            type="datetime"
            size="small"
            placeholder="选择开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>-
          <el-date-picker
            v-model="form.endDate"
            type="datetime"
            size="small"
            placeholder="选择结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发送时间">
          <el-date-picker
            v-model="form.pushDate"
             type="datetime"
            placeholder="选择日期" size="small" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <br>
        <el-form-item label="消息类型">
          <el-select v-model="form.warningWay" size="small" placeholder="">
            <el-option label="全部" value=""></el-option>
            <el-option label="邮件" value="0"></el-option>
            <el-option label="微信" value="1"></el-option>
            <el-option label="手机" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键词">
          <el-input v-model="form.keyWords" size="small" placeholder=""></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="conditionSelect" :disabled="loading">查询</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="content">
      <section class="content-wrap" >
        <el-table :data="tableData" border style="width: 100%;" max-height="calc(100% - 40px)" v-loading="loading" element-loading-text="正在查询">
          <el-table-column  :resizable="false" prop="warningWay" align="center" width="80" label="消息类型">
            <template scope="{ row }">
              <span v-if="row.warningWay ===0">
                邮件
              </span>
              <span v-if="row.warningWay ===1">
                微信
              </span>
              <span v-if="row.warningWay ===2">
                手机
              </span>
            </template>
          </el-table-column>
          <el-table-column  :resizable="false" prop="title" align="center" label="标题">
            <template scope="{ row }">
              <dl @click="details(row.id)">
                <dt>{{ row.title }}</dt>
              </dl>
            </template>
          </el-table-column>
          <el-table-column  :resizable="false" prop="receive" align="center" width="200" label="接收人">
          </el-table-column>
          <el-table-column  :resizable="false" prop="level" align="center" width="80" label="级别">
          </el-table-column>
          <el-table-column  :resizable="false" prop="publishDate" align="center" width="200" label="发布时间" :formatter="dateFormat1">
          </el-table-column>
          <el-table-column  :resizable="false" prop="pushDate" align="center" width="200" label="发送时间" :formatter="dateFormat">
          </el-table-column>
          <el-table-column  :resizable="false" prop="status" align="center" width="100" label="发送状态">
            <template scope="{ row }">
              <el-tag type="success" v-if="row.status ===0">
                发送成功
              </el-tag>
              <el-tag v-if="row.status ===1">
                发送失败
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <section class="list-bottom">
        <span class="count">共{{count}}条记录</span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage" :page-sizes="[10 , 20, 30, 50]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
          :total="count">
        </el-pagination>
      </section>
      <el-dialog title="预警详情" :visible.sync="dialogVisible" width="910px" :before-close="handleClose">
        <template>
          <span class="title">{{data.title}}</span>
          <div class="details">
            <p>[预警]</p>
            <p>标题：{{data.title}}</p>
            <!-- <p>摘要：{{data.summary}}</p> -->
            <p>网站：{{data.sourceName}}</p>
            <p>网址：{{data.url}}</p>
          </div>
        </template>
      </el-dialog>
    </section>
  </section>
</template>

<script>
import { listWarningMessagPushLog } from '@/api/settings/warningMessagPushLog'
export default {
  data () {
    return ({
      form: {
        releaseTime: '',
        warningWay: '',
        keyWords: '',
        pushDate: '',
        startDate: '',
        endDate: ''
      },
      activeName: 'all',
      mode: 'list',
      tableData: [],
      data: {},
      count: 2,
      currentPage: 1,
      limit: 10,
      dialogVisible: false,
      showStatusFlag: true,
      loading:false
    })
  },
  methods: {
    async conditionSelect () {
      this.loading = true
      this.currentPage = 1
      this.limit = 10
      try {
        const res = await listWarningMessagPushLog({
          pageSize: this.limit,
          current: this.currentPage,
          warningWay: this.form.warningWay,
          keyWord: this.form.keyWords,
          pushDate: this.form.pushDate,
          startDate: this.form.startDate,
          endDate: this.form.endDate
        })
        this.tableData = res.data
        this.count = res.count
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    handleSizeChange (val) {
      this.limit = val
      this.viewsListWarningMessagPushLog(val, this.currentPage)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.viewsListWarningMessagPushLog(this.limit, val)
    },
    handleClick (tab, event) {
      console.log(tab.name)
    },
    handleOver (row) {
      row.dropdownShow = true
    },
    handleLeave (row) {
      row.dropdownShow = false
    },
    clickIgnore (row) {
      row.dropdownShow = false
    },
    clickRemove (row) {
      row.dropdownShow = false
    },
    details (id) {
      this.tableData.forEach((item, index) => {
        if (item.id === id) {
          this.data = item
        }
      })
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    },
    showStatusPanel () {
      this.showStatusFlag = true
    },
    hideStatusPanel () {
      this.showStatusFlag = false
    },
    dateFormat (row, column) {
       var time = row.pushDate.replace(/-/g, '/').substr(0, row.pushDate.indexOf('.'))
       var t = new Date(time)
       var year = t.getFullYear()
       var month = t.getMonth() + 1
       var day = t.getDate()
       var hour = t.getHours()
       var min = t.getMinutes()
       var sec = t.getSeconds()
       var newTime = year + '-' +
     　　  (month < 10 ? '0' + month : month) + '-' +
    　　   (day < 10 ? '0' + day : day) + ' ' +
     　　  (hour < 10 ? '0' + hour : hour) + ':' +
     　　  (min < 10 ? '0' + min : min) + ':' +
     　　  (sec < 10 ? '0' + sec : sec)
       return newTime
     },  dateFormat1 (row, column) {
       var time = row.publishDate.replace(/-/g, '/').substr(0, row.publishDate.indexOf('.'))
       var t = new Date(time)
       var year = t.getFullYear()
       var month = t.getMonth() + 1
       var day = t.getDate()
       var hour = t.getHours()
       var min = t.getMinutes()
       var sec = t.getSeconds()
       var newTime = year + '-' +
     　　  (month < 10 ? '0' + month : month) + '-' +
    　　   (day < 10 ? '0' + day : day) + ' ' +
     　　  (hour < 10 ? '0' + hour : hour) + ':' +
     　　  (min < 10 ? '0' + min : min) + ':' +
     　　  (sec < 10 ? '0' + sec : sec)
       return newTime
     },
    async viewsListWarningMessagPushLog () {
      this.loading = true
      try {
        const res = await listWarningMessagPushLog({
          pageSize: this.limit,
          current: this.currentPage,
          warningWay: this.form.warningWay,
          keyWord: this.form.keyWords,
          pushDate: this.form.pushDate,
          startDate: this.form.startDate,
          endDate: this.form.endDate
        })
        console.log(res)
        this.tableData = res.data
        this.count = res.count
        this.data = res.data
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    }
  },
  mounted () {
    var d = new Date()
    var year = d.getFullYear()
    var month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)//得到月
    var day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()//得到日
    var hour = ' 00:00:00'
    var hour1 = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()// 得到时
    var minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()// 得到分
    var second = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()// 得到秒

    this.form.startDate = `${year}-${month}-${day}${hour}`
    this.form.endDate = `${year}-${month}-${day}` + ' ' + `${hour1}:${minute}:${second}`
    this.viewsListWarningMessagPushLog()
  }
}
</script>

<style lang="scss">
  .send-list {
    height: 100%;

    .filter {
      width: 100%;
      height: 150px;
      background: #fff;
      border-radius: 2px;
      padding-top: 15px;
      padding-left: 20px;
      box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
    }

    .content {
      width: 100%;
      min-height: calc(100% - 170px);
      margin-top: 20px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
      padding: 20px 30px;
      position: relative;

      .controller {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .el-radio-button {
          width: 35px;
          height: 30px;
          padding: 0;

          .el-radio-button__inner {
            padding: 7px 10px;
          }
        }
      }

      .el-table {
        margin-bottom: 50px;
        /* overflow: visible;

        .el-table__body-wrapper {
          overflow: visible;
        } */

        .cell {
          .ellipsis {
            &:focus {
              cursor: pointer;
              outline: none;
            }
          }

          .dropdown {
            position: absolute;
            z-index: 999;
            background: #fff;
            left: 0;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 2px;

            .el-dropdown-menu__item {
              position: relative;
              padding: 0 10px;

              .statusMenu {
                width: 80px;
                box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
                background: #fff;
                text-align: center;
                position: absolute;
                top: 0;
                left: -80px;

                .status li {
                  width: 80px;
                  margin-left: -40px;
                  color: #606266;

                  &:hover {
                    color: #409EFF;
                  }
                }
              }
            }
          }

          dl {
            font-size: 14px;
            margin: 0;
            cursor: pointer;

            dt {
              color: #409EFF;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            dd {
              color: #909399;
              margin-inline-start: 0;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      .list-bottom {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;

        .count {
          line-height: 32px;
        }
      }

      .selected {
        background: rgba(64, 158, 255, .2);
      }

      .el-dialog {
        border-radius: 10px;

        .el-dialog__header {
          border-bottom: 1px solid #D7D7D7;

          .el-dialog__title {
            font-size: 20px;
          }
        }

        .el-dialog__body {
          .title {
            color: #333333;
            font-size: 24px;
          }

          .details {
            padding: 20px;
            border: 1px solid #D7D7D7;
            border-radius: 10px;
            margin-top: 16px;

            p {
              margin: 0;
              margin-bottom: 5px;
              font-size: 16px;
              color: #333;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
</style>
